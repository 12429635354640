import { Site } from "tabler-react";
import styled from "styled-components";

import { FleetAlertMap } from "./components";

const IMAGE_URL =
  "https://safety-iq-ui-assets-public.s3.us-east-2.amazonaws.com/safety-iq-full-dark.png";

const SiteWrapper = styled.div`
  width: 100%;
  height: 100%;

  .header.py-4 {
    display: ${(props) => (props.tabsVisible ? "block" : "none")};
  }

  .header.d-lg-flex {
    display: ${(props) => (props.tabsVisible ? "flex" : "none !important")};
  }

  .header:nth-of-type(2) .col-lg-3 {
    display: none;
  }

  .dropdown-item {
    cursor: pointer;
    &:active {
      &,
      .dropdown-icon {
        color: #fff !important;
      }
    }
  }
`;

function App() {
  return (
    <SiteWrapper tabsVisible={true}>
      <Site.Wrapper headerProps={{ imageURL: IMAGE_URL }}>
        <FleetAlertMap />
      </Site.Wrapper>
    </SiteWrapper>
  );
}

export default App;
