import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

import { MOMENT_FORMAT } from "../../../constants/misc";

// const dateComparator = ( valueA, valueB ) => {
//   return moment(valueB).diff(moment(valueA), 'seconds');
// };

const columnDefs = [
  { headerName: "Vehicle Name", field: "name" },
  { headerName: "VIN", field: "vin" },
  { headerName: "Last Vehicle Check-in", field: "lasttimestamplocal" }, //, comparator: dateComparator },
];

const TableView = ({
  data,
  hoveredVehicleId,
  setHoveredVehicleId,
  isMobileDevice,
}) => {
  const [gridOptions, setGridOptions] = useState(null);
  data = Object.assign([], data);
  data = data.map((v) => ({
    ...v,
    lasttimestamplocal: moment(v.lasttimestamplocal).format(MOMENT_FORMAT),
  }));

  useEffect(() => {
    if (gridOptions) {
      if (hoveredVehicleId) {
        const gridIndex = data.findIndex((d) => d.id === hoveredVehicleId);
        // // select row in table
        gridOptions.api.getDisplayedRowAtIndex(gridIndex).setSelected(true);

        // scroll to make row visible if necessary
        gridOptions.api.ensureIndexVisible(gridIndex, "bottom");
      } else {
        gridOptions.api.deselectAll();
      }
    }
  }, [data, gridOptions, hoveredVehicleId]);

  const onCellMouseOver = (row, ...props) => {
    setHoveredVehicleId(row.data.id);
  };

  const defaultColDef = {
    filter: false,
    sortable: false,
    resizable: true,
    menuTabs: [],
    tooltipValueGetter: (params) => "Click to highlight vehicle on map"
  };

  return (
    <div
      className="ag-theme-balham ag-theme-top-right-border-only"
      style={{ height: isMobileDevice ? "250px" : "550px", width: "100%" }}
    >
      <AgGridReact
        rowHeight={40}
        headerHeight={40}
        columnDefs={columnDefs}
        rowData={data}
        defaultColDef={defaultColDef}
        pagination={false}
        suppressCellSelection={true}
        rowSelection={"single"}
        onGridReady={(gridOptions) => setGridOptions(gridOptions)}
        overlayNoRowsTemplate={"No vehicles to show"}
        onRowClicked={onCellMouseOver}
      />
    </div>
  );
};

export default TableView;
