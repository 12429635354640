export const AG_GRID_KEY =
  "CompanyName=Mobikit, Inc.,LicensedApplication=Mobikit,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-012153,ExpiryDate=5_June_2022_[v2]_MTY1NDM4MzYwMDAwMA==0095576c84020aea18e94fcaf4ea9267";

export const MAPBOX_API_KEY =
  "pk.eyJ1IjoibW9iaWtpdCIsImEiOiJjanV2N2s1d2wwcWtsNDRwamt2ZXZsYmlzIn0.pNBTYHt_UAwOt7mYaPnTNw";

export const MAP_STYLES = [
  {
    id: "mobikit_light",
    label: "Mobikit Light",
    url: "mapbox://styles/mobikit/cjux4icas4gi11fquj53u3p98",
    icon: "https://s3.amazonaws.com/mobikit.public/assets/light.png",
    // layerGroups: DEFAULT_LAYER_GROUPS
  },
  {
    id: "mobikit_satellite",
    label: "Mobikit Satellite",
    url: "mapbox://styles/mobikit/cjuwpsfk703jb1fo4scr6qa1j",
    icon: "https://s3.amazonaws.com/mobikit.public/assets/satellite.png",
    // layerGroups: DEFAULT_LAYER_GROUPS
  },
  {
    id: "mobikit_dark",
    label: "Mobikit Dark",
    url: "mapbox://styles/mobikit/cjux4iv7s5mv01gpjpm0ev0ye",
    icon: "https://s3.amazonaws.com/mobikit.public/assets/dark.png",
    // layerGroups: DEFAULT_LAYER_GROUPS
  },
  {
    id: "mobikit_streets",
    label: "Mobikit Streets",
    url: "mapbox://styles/mobikit/cjux4jbxp0gip1fs3s2vk6fh9",
    icon: "https://s3.amazonaws.com/mobikit.public/assets/streets.png",
    // layerGroups: DEFAULT_LAYER_GROUPS
  },
];
