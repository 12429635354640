import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    .flatpickr-months {
      .flatpickr-prev-month, .flatpickr-next-month {
        &:hover {
          color: ${({ theme }) => theme.activeColor} !important;
          svg {
            fill: ${({ theme }) => theme.activeColor} !important;
          }
        }
      }
    }

    .flatpickr-day.today:not(.selected) {
      & {
        border-bottom-color: ${({ theme }) => theme.activeColor} !important;
      }
      &:hover {
        background: #e9e9e9;
        color: #404848 !important;
        border-color: #e9e9e9 !important;
        border-bottom-color: ${({ theme }) => theme.activeColor} !important;
      }
    }

    .overflow-hidden {
      overflow: hidden;
    }
    select,
    #time-window-start,
    #time-window-end {
      cursor: pointer;
      transition: 100ms linear;
      &:focus {
        outline: 0;
        border-color: ${({ theme }) => theme.activeColor} !important;
        box-shadow: none !important;
      }
    }
    .ag-theme-top-right-border-only {
      .ag-root-wrapper {
        border-left: none !important;
        border-bottom: none !important;
      }
    }
    .ag-theme-top-border-only {
      .ag-root-wrapper {
        border-left: none !important;
        border-right: none !important;
        border-bottom: none !important;
      }
    }

    .ag-theme-balham {
      font-family: "Source Sans Pro";
      font-size: 15px;
      color: ${({ theme }) => theme.darkGreyColor};

      // the pinned container is off 1px
      &-insights {
        .ag-pinned-right-cols-container {
          margin-top: -1px;
        }
      }

      & .ag-center-cols-container {
        margin-top: -1px !important;
      }

      & .ag-root-wrapper {
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 40, 100, 0.12);
        border-radius: 3px;
      }

      & .ag-header {
        background-color: #fff;
        border-bottom: 1px solid rgba(0, 40, 100, 0.12);
      }

      & .ag-header-row {
        font-weight: 400;
      }

      & .ag-filter-toolpanel-header {
        font-weight: 400;
        cursor: pointer;
        & * {
          cursor: pointer;
        }
      }

      & .ag-group-contracted {
        height: 40px !important;
      }

      & .ag-group-expanded {
        height: 40px !important;
      }

      & .ag-selected .ag-side-button-button {
        background-color: #e8f2fc;
      }

      & .ag-side-button-button {
        border-top: 1px solid rgba(0, 40, 100, 0.12);
        border-bottom: 1px solid rgba(0, 40, 100, 0.12);
      }

      & .ag-row {
        color: ${({ theme }) => theme.darkGreyColor};
        cursor: pointer;
        border-top: 1px solid rgba(0, 40, 100, 0.12);
        &:hover {
          background-color: ${({ theme }) => theme.lightGreyColor};
        }
      }

      & .ag-row-odd {
        background-color: #fff;
      }

      & .ag-row-selected {
        background-color: ${({ theme }) => theme.primaryColorLT};
        &:hover {
          background-color: ${({ theme }) => theme.primaryColorLT};
        }
      }

      & .ag-cell {
        line-height: 40px;
        &-kebab-menu {
          padding: 0;
        }
      }

      & .ag-paging-panel {
        justify-content: center;
      }

      & .ag-group-title {
        color: ${({ theme }) => theme.darkGreyColor};
      }

      & .ag-group-title-bar {
        background-color: #e8f2fc;
      }

      & .ag-tool-panel-wrapper {
        background-color: #e8f2fc;
        width: 300px;
      }

      & .ag-filter-toolpanel-search {
        padding-left: 10px !important;
        padding-right: 10px !important;
        height: 43px;
      }

      & .ag-filter-toolpanel-search-input {
        margin-right: 0px !important;
      }

      & .ag-input-field-input {
        height: 30px;
        border-color: #bdc3c7;
      }

      & .ag-filter-toolpanel-group-level-0 {
        padding: 5px;
      }

      & .ag-filter-toolpanel-instance-filter {
        border: 0;
      }

      & .ag-filter-apply-panel {
        border-top: 0;
      }

      & .ag-picker-field-wrapper {
        border-radius: 0px;
        margin-bottom: 10px;
      }

      & .ag-filter-apply-panel-button {
        color: #495057;
        background-color: #fff;
        border: 1px solid rgba(0, 40, 100, 0.12);
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        cursor: pointer;
        font-weight: 600;
        font-size: 13px;
        padding: 6px;

        &:hover {
          background-color: #fafafa;
        }
      }

      & .ag-side-buttons {
        width: 30px;
        color: ${({ theme }) => theme.darkGreyColor};
      }

      & .ag-column-select {
        border-top: 0px;
      }

      & .ag-column-select-header {
        height: 43px;
        padding-left: 10px;
        padding-right: 10px;
      }

      & .ag-column-select-column {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 4px;
      }

      & .ag-column-drop-vertical {
        padding: 6px;
      }

      & .ag-column-drop-vertical-title {
        color: ${({ theme }) => theme.darkGreyColor};
      }

      & .ag-filter-condition {
        align-items: center;
        height: 35px;
        margin-bottom: 8px;
      }

      & .ag-radio-button-label {
        margin-bottom: 0px;
      }

      & .ag-group-title:after {
        line-height: 22px !important;
      }

      & .ag-filter-toolpanel-group-title-bar-icon {
        margin-top: 3px;
      }
    }

    .mapboxgl-map {
      font-family: inherit;
    }

   /* Driver Risk dashboard - Select Risk Metric vertically centered */
    div[data-tut="select-metric"] {
      .dimmer-content {
        display: flex;
        align-items: center;
      }
    }
  }
`;
