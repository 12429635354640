import React, { Fragment, useEffect, useState } from "react";
import { Badge, Card, Dimmer, Page, Header } from "tabler-react";
import moment from "moment";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import { InvalidTokenAlert, MapView, TableView } from "./components";
import { parseQueryParamsFromUrl } from "../../utils";
import { fetchTokenData, MOMENT_FORMAT } from "../../constants/misc";

const StyledTooltip = styled(ReactTooltip)`
  box-shadow: 0 2px 5px 0 rgba(32, 32, 32, 0.1);
  width: auto !important;
`;

const MAX_FLEET_NAME_CHAR_LENGTH = 45;

const FleetAlertMap = () => {
  const queryParams = parseQueryParamsFromUrl();
  const { token } = queryParams;
  const [tokenData, setTokenData] = useState(null);
  const [tokenValid, setTokenValid] = useState(true);
  const [hoveredVehicleId, setHoveredVehicleId] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  // match mobile devices
  const isMobileDevice = window.matchMedia("(max-width: 600px)").matches;

  // show tooltip giving entire fleet name if too long
  useEffect(() => {
    if (tokenData && tokenData.fleet_name && !isMobileDevice) {
      if (tokenData.fleet_name.length > MAX_FLEET_NAME_CHAR_LENGTH) {
        setShowTooltip(true);
      }
    }
  }, [tokenData, isMobileDevice]);

  useEffect(() => {
    const fetchData = async () => {
      const url = window.location.href;
      const isStagingUrl = url.includes("staging");
      const tokenData = await fetchTokenData(isStagingUrl, token);
      if (tokenData.error) {
        setTokenValid(false);
        setTokenData({ error: tokenData.error });
      } else {
        tokenData.vehicles = tokenData.vehicles.map((v) => ({ ...v, id: v.device_id }));
        setTokenData(tokenData);
        setTokenValid(true);
      }
      // setTokenData(DUMMY_DATA);
      // setTokenValid(true);
    };
    fetchData();
  }, [token]);

  return token ? (
    <Page.Content>
      <Dimmer loader active={!tokenData}>
        {tokenData && tokenValid ? (
          <Fragment>
            <Header.H1 className="ml-4">Fleet Alerts</Header.H1>
            <Card>
              <div className="mt-2">
                <Card.Header>
                  <div className="w-100 d-md-flex justify-content-md-between" style={{overflowX: "scroll"}}>
                    <span
                      data-tip
                      data-for={"header-overview"}>
                      <Header.H2>
                        Fleet Name: <Badge>{showTooltip ? tokenData.fleet_name.slice(0, MAX_FLEET_NAME_CHAR_LENGTH) + "..." : tokenData.fleet_name}</Badge>
                      </Header.H2>
                    </span>
                    {showTooltip && (
                      <StyledTooltip
                        id={"header-overview"}
                        type="light"
                        effect="solid"
                        place="top">
                        <span>{tokenData.fleet_name}</span>
                      </StyledTooltip>
                    )}
                    <Header.H2>
                      Report Generated:{" "}
                      <Badge>
                        {moment(tokenData.created)
                          .local()
                          .format(MOMENT_FORMAT)}
                      </Badge>
                    </Header.H2>
                  </div>
                </Card.Header>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <TableView
                    data={tokenData.vehicles}
                    hoveredVehicleId={hoveredVehicleId}
                    setHoveredVehicleId={setHoveredVehicleId}
                    isMobileDevice={isMobileDevice}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <div
                    style={{
                      height: isMobileDevice ? "250px" : "100%",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <MapView
                      data={tokenData.vehicles}
                      hoveredVehicleId={hoveredVehicleId}
                      setHoveredVehicleId={setHoveredVehicleId}
                      isMobileDevice={isMobileDevice}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Fragment>
        ) : (
          tokenData && <InvalidTokenAlert message={tokenData.error} />
        )}
      </Dimmer>
    </Page.Content>
  ) : (
    <InvalidTokenAlert />
  );
};

export default FleetAlertMap;
