import React, { useEffect, useRef } from "react";
import DeckGL from "@deck.gl/react";
import { IconLayer } from "@deck.gl/layers";
import { StaticMap } from "react-map-gl";
import { WebMercatorViewport } from "@deck.gl/core";

import { MAPBOX_API_KEY, MAP_STYLES } from "../../../constants/keys";
import marker from "../../../assets/marker.png";

const MAP_STYLE = MAP_STYLES.filter((v) => v.label === "Mobikit Light")[0].url;

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, mask: true },
};

const NULL_VIEW_STATE = {
  latitude: 25.7825453,
  longitude: -80.2994987,
  zoom: 2.3,
};

const MapView = ({
  data,
  hoveredVehicleId,
  setHoveredVehicleId,
  isMobileDevice,
}) => {
  const mapRef = useRef(null);

  // useEffect(() => {
  //   if (mapRef && mapRef.current) {
  //     console.log(mapRef);
  //     let mapboxObj = mapRef.current.getMap();
  //     mapboxObj.addControl(new ScaleControl(), 'bottom-right')
  //   }
  // }, [mapRef]);

  // Generate appropriate view state for data bounds
  const generateViewState = () => {
    const lngs = data.map((r) => r.lng);
    const lats = data.map((r) => r.lat);
    const boundingBox = [
      [Math.min(...lngs), Math.min(...lats)],
      [Math.max(...lngs), Math.max(...lats)],
    ];

    let viewState = {
      ...NULL_VIEW_STATE,
      minZoom: 0,
      maxZoom: 20,
      bearing: 0,
      pitch: 0,
      width: isMobileDevice ? 349 : 590,
      height: isMobileDevice ? 250 : 550,
    };
    // correct view state with bounding box
    const initialViewPort = new WebMercatorViewport(viewState);
    const updatedViewPort = initialViewPort.fitBounds(boundingBox);
    viewState = {
      ...viewState,
      longitude: updatedViewPort.longitude,
      latitude: updatedViewPort.latitude,
      zoom: Math.min(updatedViewPort.zoom - 0.5, 16),
      bearing: updatedViewPort.bearing,
    };

    return viewState;
  };

  const layers = [
    new IconLayer({
      id: "icon-layer",
      data,
      iconAtlas: marker,
      iconMapping: ICON_MAPPING,
      getIcon: (d) => "marker",
      sizeScale: 20,
      getPosition: (d) => [d.lng, d.lat],
      getSize: (d) => 3,
      getColor: (d) => (d.id === hoveredVehicleId ? [255, 165, 0] : [0, 0, 0]),
      pickable: true,
      // autoHighlight: true,
      billboard: true,
      onHover: (info) =>
        setHoveredVehicleId(info.object ? info.object.id : null),
      onClick: (info) =>
        setHoveredVehicleId(info.object ? info.object.id : null),
      highlightColor: [255, 165, 0],
      highlightedObjectIndex: data.findIndex((d) => d.id === hoveredVehicleId),
    }),
  ];

  return (
    <DeckGL
      initialViewState={generateViewState()}
      controller={true}
      layers={layers}
      height="100%"
      width={"100%"}
    >
      <StaticMap
        reuseMaps
        preventStyleDiffing={true}
        mapStyle={MAP_STYLE}
        mapboxApiAccessToken={MAPBOX_API_KEY}
      />
    </DeckGL>
  );
};

export default MapView;
