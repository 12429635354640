export function parseQueryParamsFromUrl() {
  let queryString = window.location.search;
  const query = queryString.substr(1);
  const result = {};
  query.split("&").forEach(function (part) {
    let [key, value] = part.split("=");
    value = decodeURIComponent(decodeURIComponent(value));
    result[key] = value;
  });
  return result;
}
