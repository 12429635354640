import React from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import { LicenseManager } from "ag-grid-enterprise";

import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { AG_GRID_KEY } from "./constants/keys";
// Styles
import "./index.css";
import { ThemeProvider } from "styled-components";
import { theme } from "./constants/theme";
import { GlobalStyle } from "./globalStyle";
import "tabler-react/dist/Tabler.css";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// initialize Ag Grid
LicenseManager.setLicenseKey(AG_GRID_KEY);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
