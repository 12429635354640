// Colors
export const primaryColorLT = "#d9e3f3";
export const primaryColor = "#467fcf";
export const primaryColorDark = "#316cbe";
export const lightGreyColor = "#F5F7FB";
export const mediumGreyColor = "#9aa0ac";
export const darkGreyColor = "#495057";
export const whiteColor = "#ffffff";
export const successColor = "#5eba00";
export const errorColorLT = "#f5d2d2";
export const errorColorMedium = "#f1c1c0";
export const errorColor = "#cd201f";
export const errorColorDark = "#ac1b1a";
export const warningColor = "#f1c40f";
export const purpleColor = "#a55eea";
export const purpleColorDark = "#8642c9";
export const tealColor = "#2bcbba";
export const pinkColor = "#f66d9b";
export const purpleColorLT = "#ddcdec";
export const inputColor = darkGreyColor;
export const titleColorLT = mediumGreyColor;
export const inputBgd = whiteColor;

// dimensions
export const NAV_DIMENSIONS = {
  HEIGHT: 120,
};

// Styles
export const borderRadius = "3px";

// Overall theme
export const theme = {
  // Colors
  primaryColor,
  primaryColorLT,
  primaryColorDark,
  lightGreyColor,
  mediumGreyColor,
  darkGreyColor,
  whiteColor,
  errorColorLT,
  errorColorMedium,
  errorColor,
  errorColorDark,
  warningColor,
  successColor,
  purpleColor,
  purpleColorLT,
  purpleColorDark,
  pinkColor,
  tealColor,

  // Text
  labelColor: mediumGreyColor,
  labelHoverColor: mediumGreyColor,
  labelColorLT: mediumGreyColor,
  textColor: darkGreyColor,
  textColorLT: mediumGreyColor,
  titleColorLT,
  subtextColor: mediumGreyColor,
  subtextColorLT: mediumGreyColor,
  subtextColorActive: primaryColor,
  titleTextColor: darkGreyColor,
  textColorHl: primaryColorDark,
  textColorHlLT: primaryColor,
  activeColor: primaryColor,
  activeColorHover: primaryColorDark,

  // Buttons
  primaryBtnBgd: primaryColor,
  primaryBtnActBgd: primaryColorDark,
  primaryBtnColor: whiteColor,
  primaryBtnActColor: whiteColor,
  primaryBtnBgdHover: primaryColorDark,
  primaryBtnRadius: borderRadius,

  secondaryBtnBgd: primaryColor,
  secondaryBtnActBgd: primaryColorDark,
  secondaryBtnColor: whiteColor,
  secondaryBtnActColor: whiteColor,
  secondaryBtnBgdHover: primaryColorDark,

  linkBtnBgd: "transparent",
  linkBtnActBgd: "transparent",
  linkBtnColor: mediumGreyColor,
  linkBtnActColor: lightGreyColor,
  linkBtnActBgdHover: "transparent",

  negativeBtnBgd: errorColor,
  negativeBtnActBgd: errorColorDark,
  negativeBtnBgdHover: errorColorDark,
  negativeBtnColor: whiteColor,
  negativeBtnActColor: whiteColor,

  // Input
  inputBoxHeight: "34px",
  inputPadding: "4px 10px",
  inputFontSize: "11px",
  inputFontWeight: 500,
  inputBgd,
  inputBgdHover: whiteColor,
  inputBgdActive: whiteColor,
  inputBorderColor: mediumGreyColor,
  inputBorderHoverColor: mediumGreyColor,
  inputBorderActiveColor: primaryColorDark,
  inputColor: darkGreyColor,
  inputBorderRadius: borderRadius,
  inputPlaceholderColor: mediumGreyColor,
  inputPlaceholderFontWeight: 400,
  secondaryInputHeight: "28px",
  secondaryInputBgd: whiteColor,
  secondaryInputBgdHover: whiteColor,
  secondaryInputBgdActive: whiteColor,
  secondaryInputColor: darkGreyColor,
  secondaryInputBorderColor: mediumGreyColor,
  secondaryInputBorderActiveColor: primaryColor,

  // Select
  selectColor: inputColor,
  selectColorLT: titleColorLT,
  selectActiveBorderColor: primaryColorDark,
  selectFontSize: "11px",
  selectFontWeight: "400",
  selectFontWeightBold: "500",
  selectColorPlaceHolder: mediumGreyColor,
  selectBackground: lightGreyColor,
  selectBackgroundHover: whiteColor,
  selectBackgroundLT: mediumGreyColor,
  selectBackgroundHoverLT: mediumGreyColor,
  selectBorderColor: mediumGreyColor,
  selectBorderColorLT: mediumGreyColor,
  selectBorderRadius: borderRadius,
  selectBorder: "1px",
  dropdownListHighlightBg: mediumGreyColor,
  dropdownListShadow: "0 6px 12px 0 rgba(32,32,32,0.16)",
  dropdownListBgd: lightGreyColor,
  dropdownListBorderTop: "transparent",

  // Switch
  switchWidth: 24,
  switchHeight: 12,
  switchLabelMargin: 12,
  switchTrackBgd: lightGreyColor,
  switchTrackBgdActive: primaryColor,
  switchTrackBorderRadius: "6px",
  switchBtnBgd: whiteColor,
  switchBtnBgdActive: whiteColor,
  switchBtnBoxShadow: "0 2px 4px 0 rgba(32,32,32,0.40)",
  switchBtnBorderRadius: "6px",
  switchBtnWidth: "12px",
  switchBtnHeight: "12px",
  secondarySwitchTrackBgd: lightGreyColor,
  secondarySwitchBtnBgd: whiteColor,

  // Checkbox
  checkboxWidth: 16,
  checkboxHeight: 16,
  checkboxMargin: 12,
  checkboxBorderColor: mediumGreyColor,
  checkboxBorderRadius: borderRadius,
  checkboxBorderColorLT: primaryColorDark,
  checkboxBoxBgd: whiteColor,
  checkboxBoxBgdChecked: primaryColor,

  // Side Panel
  sidePanelHeaderBg: whiteColor,
  sidePanelBg: whiteColor,
  sideBarCloseBtnBgd: whiteColor,
  sideBarCloseBtnColor: darkGreyColor,
  sideBarCloseBtnBgdHover: lightGreyColor,
  panelBackground: lightGreyColor,
  panelBackgroundHover: lightGreyColor,
  panelActiveBg: lightGreyColor,
  panelActiveBgLT: whiteColor,
  panelHeaderIcon: mediumGreyColor,
  panelHeaderIconActive: primaryColor,
  panelHeaderHeight: 48,
  panelBoxShadow: "0 6px 12px 0 rgba(32,32,32,0.16)",
  panelBorderRadius: borderRadius,
  panelBackgroundLT: whiteColor,
  panelBorderColor: mediumGreyColor,
  panelBorder: `1px solid ${mediumGreyColor}`,
  panelBorderLT: `1px solid ${mediumGreyColor}`,
  mapPanelBackgroundColor: whiteColor,
  mapPanelHeaderBackgroundColor: whiteColor,
  tooltipBg: lightGreyColor,
  tooltipColor: darkGreyColor,

  // Slider
  sliderBarColor: primaryColor,
  sliderBarBgd: mediumGreyColor,
  sliderBarHoverColor: mediumGreyColor,
  sliderBarRadius: borderRadius,
  sliderBarHeight: 4,
  sliderHandleHeight: 12,
  sliderHandleWidth: 12,
  sliderHandleColor: whiteColor,
  sliderHandleHoverColor: whiteColor,
  sliderHandleShadow: "0 2px 4px 0 rgba(32,32,32,0.40)",
  sliderInputHeight: 24,
  sliderInputWidth: 50,

  // Plot
  rangeBrushBgd: mediumGreyColor,
  histogramFillInRange: primaryColor,
  histogramFillOutRange: primaryColorLT,
};
