import axios from "axios";

export const MOMENT_FORMAT = "YYYY-MM-DD hh:mm:ss A";

const BASE_API_URL = "https://phly.safetyiq.io/api";

export async function fetchTokenData(isStagingUrl, token) {
  const apiUrl = isStagingUrl
    ? BASE_API_URL.replace("phly", "phly-staging")
    : BASE_API_URL;
  const resp = await axios({
    url: `${apiUrl}/datasets/fleet_result/${token}`,
    method: "get",
    withCredentials: true,
  }).catch((err) => {
    if (err && err.response) {
      if (err.response.status === 404) {
        return { data: { error: "The provided token is invalid." } };
      } else if (err.response.status === 403) {
        return { data: { error: "The provided token has expired." } };
      }
    }
    return { data: { error: "The server encountered an unexpected error." } };
  });

  return resp.data;
}
