import React from "react";
import { Alert, Header, Page } from "tabler-react";

const InvalidTokenAlert = ({ message }) => {
  return (
    <Page.Content>
      <Alert type="danger">
        <div className="mt-2 text-center">
          <Header.H2>{message}</Header.H2>
          <Header.H4>Please contact an administrator for assistance.</Header.H4>
        </div>
      </Alert>
    </Page.Content>
  );
};

export default InvalidTokenAlert;
